import { Switch, SwitchProps, styled } from "@mui/material";
import { ReactElement } from "react";
import colors from "../../../colors";
import { CabComponentProps } from "../cabStyled";


export interface CabSwitchProps extends CabComponentProps<SwitchProps> {
  color?: SwitchProps['color'];
  disabled?: SwitchProps['disabled'];
  onChange?: SwitchProps['onChange'];
  checked?: boolean;
  defaultChecked?: boolean;
  constantColor?: boolean;
}

export const CabSwitch = ({
  color, disabled, onChange, overrides, checked, defaultChecked, sx, className, constantColor
}: CabSwitchProps): ReactElement => {
  const switchProps: SwitchProps = {
    color,
    disabled,
    onChange,
    checked,
    sx,
    defaultChecked,
    className,
  };

  return (
    <SwitchStyled {...switchProps} {...overrides} constantColor={constantColor}/>
  );
};

const SwitchStyled = styled(
  Switch, {label: "SwitchStyled", shouldForwardProp: (propName) => propName !== 'constantColor'},
)<CabComponentProps<SwitchProps> & {constantColor?: boolean}>(({ theme, constantColor }) => ({
  width: 40,
  height: 24,
  padding: 0,
  margin: 5,
  ...(theme.palette.mode !== 'dark' ? {
    '& .MuiSwitch-switchBase': {
      padding: 0,
      margin: 2,
      '&.Mui-checked': {
        transform: 'translateX(16px)',
        color: '#ffffff',
        '& + .MuiSwitch-track': {
          backgroundColor: colors.lavendar950,
          opacity: 1,
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          backgroundColor: colors.black600,
        },
        '&:hover': {
          '& + .MuiSwitch-track': {
            backgroundColor: colors.black900,
            opacity: 1,
          }
        }
      },
      '&.Mui-disabled .MuiSwitch-thumb': {
        color: colors.white700,
        opacity: 1
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        backgroundColor: colors.black200,
        opacity: 1,
      },
    },
    '& .MuiSwitch-track': {
      borderRadius: 26 / 2,
      backgroundColor: constantColor ? colors.lavendar950 : colors.black400,
      opacity: 1,
    },
    '&:hover': {
      '& .MuiSwitch-track': {
        backgroundColor: constantColor ? colors.black900 : colors.black500,
        opacity: 1,
      }
    },
  } : {
    '& .MuiSwitch-switchBase': {
      padding: 0,
      margin: 2,
      '&.Mui-checked': {
        transform: 'translateX(16px)',
        color: '#ffffff',
        '& + .MuiSwitch-track': {
          backgroundColor: colors.lavendar600,
          opacity: 1,
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          backgroundColor: colors.white400,
          opacity: 1,
        },
        '&:hover': {
          '& + .MuiSwitch-track': {
            backgroundColor: colors.lavendar900,
            opacity: 1,
          }
        }
      },
      '&.Mui-disabled .MuiSwitch-thumb': {
        color: colors.white500,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        backgroundColor: colors.white100,
        opacity: 1,
      },
    },
    '& .MuiSwitch-track': {
      borderRadius: 26 / 2,
      backgroundColor: constantColor ? colors.lavendar600 : colors.white300,
      opacity: 1,
    },
    '&:hover': {
      '& .MuiSwitch-track': {
        backgroundColor: constantColor ? colors.lavendar900 : colors.white400,
        opacity: 1,
      }
    },
  }),
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 20,
    height: 20,
    opacity: 1,
  },
}));
