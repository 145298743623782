import { memo, ReactElement } from 'react'; 
import { Box } from '@mui/material';
import { CAB_PANEL_WIDTH } from '@CabComponents/CabPanel';
import { PAGE_URL } from '../../constants';
import {
  MeetingSlot, DisplayCalendarsDict, MeetingHoldEventErrorResolution, CalDateRange, Meeting,
  Calendar, InvalidParticipantVotes, Leader, MeetingHoldEventError,
  NewMeeting,
} from '../../store';
import MeetingSettingsContainer from '../../components/Schedule/MeetingSettings';
import CabinetPage from '../../components/Common/CabinetPage';
import { CabinetModal } from '../../components/Common/CabinetModal';
import MeetingErrors from '../../components/Schedule/MeetingErrors';
import { ScheduleHeaderContainer } from '../../components/Schedule/ScheduleHeader';
import CalendarSchedulerContainer from '../../components/Schedule/CalendarScheduler';
import ScheduleShareModalContainer from '../../components/Schedule/ScheduleShareModal';
import colors from '../../colors';
import DuplicateMeetingModal, {
  DuplicateMeetingSubmitProps
} from '../../components/Schedule/DuplicateMeetingModal/DuplicateMeetingModal';
import DeleteMeetingModal from '../../components/Schedule/DeleteMeetingModal';
import ReusableMeetingModal from '../../components/Schedule/ReusableMeetingModal';
import AdditionalCalendars from '../../components/Schedule/AdditionalCalendarsModal';
import CabSpinner from '@CabComponents/CabSpinner';
import { ExcludedSlotInfo, TimeZone } from '../../utils/scheduleUtils';
import { SlotInfo } from 'react-big-calendar';


const calendarSchedulerSx = { padding: 2, paddingTop: 0 };


interface ScheduleProps {
  currentMeeting: Meeting | null;
  currentMeetingErrors: MeetingHoldEventError[];
  errorModalOpen: boolean;
  handleResolution: (resolution: MeetingHoldEventErrorResolution) => void;
  calendars: Calendar[];
  selectedLeaders: number[];
  handleLeaderSelect: (leaders: number[]) => void;
  handleCancel: () => void;
  handleCreateOneOffMeeting: () => void;
  handleCreatePoll: () => void;
  handleCreateReusableMeeting: () => void;
  handleOpenMeeting: (meetingId: number) => void;
  handleCalendarClick: (calendar: string) => void; // Changed from Calendar to string based on usage
  handleCalendarTimezoneSelected: (timezone: TimeZone) => void;
  handleSecondaryTimezoneSelected: (index: number, timezone: TimeZone) => void; // Added index parameter
  handleUpdateAdditionalCalendars: (calendars: NonNullable<Calendar['calendar_access_id']>[]) => void;
  handleOpenAdditionalCalendarsModal: () => void;
  eventsExists: boolean;
  loadingEvents: boolean;
  allLoaded: boolean;
  openMeetingSettings: boolean;
  leaderHasAssociations: boolean;
  hasGrant: boolean;
  currentMeetingId: number | undefined; // Changed from number to number | undefined
  displayCalendars: DisplayCalendarsDict;
  additionalCalendarPks: NonNullable<Calendar['calendar_access_id']>[];
  loadingCalendars: boolean;
  currentDateRangeInfo: CalDateRange | null;
  setCurrentDateRangeInfo: (range: CalDateRange | null) => void;
  calendarTimezoneSelected: TimeZone;
  secondaryTimezonesSelected: Array<TimeZone | undefined>;
  selectedSlots: MeetingSlot[];
  recurringSlots: MeetingSlot[];
  showAllRecurringSlots: boolean;
  handleToggleShowAllRecurringSlots: () => void;
  handleCreateSlot: (info: SlotInfo) => void;
  handleEditSlot: (eventId: string, start: Date, end: Date, isExcluded: boolean) => void;
  handleDeleteSlots: (slots: MeetingSlot[]) => void;
  handleDuplicateMeeting: (meetingId: number) => void;
  handleDeleteMeeting: (meetingId: number) => void;
  handleShareMeeting: (meetingId: number) => void;
  handleCopyLink: (meetingId: number) => void;
  numHiddenCalendarTeammates: number;
  newMeeting: NewMeeting | null;
  navigate: (url: string) => void;
  handleConvertToOneOff: (meetingId: number) => void;
  leaderMap: Record<number, Leader>;
  currentMeetingSlots: MeetingSlot[];
  updateSlotNames: (name: string) => void;
  activeCalendars: number[];
  handleRemoveLeader: (mtgId: number, leaderId: number) => void;
  handleAddLeader: (meetingId: number, leader: number) => void;
  setSelectedLeaders: (leaders: number[]) => void;
  setErrorModalOpen: (open: boolean) => void;
  handleShareCurrentMeeting: () => void;
  updatingSlots: boolean;
  pollUpdateOpen: boolean;
  handlePollUpdateAccept: () => void;
  handlePollUpdateReject: () => void;
  invalidatedParticipantVotes: InvalidParticipantVotes;
  handleCreateExcludeSlots: (slots: ExcludedSlotInfo[]) => void;
  leaderCalendarOptions: Calendar[];
  shareMeetingModalMeetingId: number;
  setShareMeetingModalMeetingId: (id: number) => void;
  duplicateMeetingModalMeetingId: number;
  setDuplicateMeetingModalMeetingId: (id: number) => void;
  handleSubmitDuplicateMeeting: (data: DuplicateMeetingSubmitProps) => Promise<Meeting | undefined>;
  deleteMeetingModalMeetingId: number;
  setDeleteMeetingModalMeetingId: (id: number) => void;
  handleSubmitDeleteMeeting: (meeting: Meeting) => void;
  openAdditionalCalendarsModal: boolean;
  setOpenAdditionalCalendarsModal: (open: boolean) => void;
  additionalCalendars: NonNullable<Calendar['calendar_access_id']>[];
  meetingToDuplicate?: Meeting;
  meetingToDelete?: Meeting;
}

const Schedule = ({
  currentMeeting,
  currentMeetingErrors,
  errorModalOpen,
  handleResolution,
  calendars,
  selectedLeaders,
  handleLeaderSelect,
  handleCancel,
  handleCreateOneOffMeeting,
  handleCreatePoll,
  handleCreateReusableMeeting,
  handleOpenMeeting,
  handleCalendarClick,
  handleCalendarTimezoneSelected,
  handleSecondaryTimezoneSelected,
  handleUpdateAdditionalCalendars,
  handleOpenAdditionalCalendarsModal,
  eventsExists,
  loadingEvents,
  allLoaded,
  openMeetingSettings,
  leaderHasAssociations,
  hasGrant,
  currentMeetingId,
  displayCalendars,
  additionalCalendarPks,
  loadingCalendars,
  currentDateRangeInfo,
  setCurrentDateRangeInfo,
  calendarTimezoneSelected,
  secondaryTimezonesSelected,
  selectedSlots,
  recurringSlots,
  showAllRecurringSlots,
  handleToggleShowAllRecurringSlots,
  handleCreateSlot,
  handleEditSlot,
  handleDeleteSlots,
  handleDuplicateMeeting,
  handleDeleteMeeting,
  handleShareMeeting,
  handleCopyLink,
  numHiddenCalendarTeammates,
  newMeeting,
  navigate,
  handleConvertToOneOff,
  leaderMap,
  currentMeetingSlots,
  updateSlotNames,
  activeCalendars,
  handleRemoveLeader,
  handleAddLeader,
  setSelectedLeaders,
  setErrorModalOpen,
  handleShareCurrentMeeting,
  updatingSlots,
  pollUpdateOpen,
  handlePollUpdateAccept,
  handlePollUpdateReject,
  invalidatedParticipantVotes,
  handleCreateExcludeSlots,
  leaderCalendarOptions,
  shareMeetingModalMeetingId,
  setShareMeetingModalMeetingId,
  duplicateMeetingModalMeetingId,
  setDuplicateMeetingModalMeetingId,
  handleSubmitDuplicateMeeting,
  deleteMeetingModalMeetingId,
  setDeleteMeetingModalMeetingId,
  handleSubmitDeleteMeeting,
  openAdditionalCalendarsModal,
  setOpenAdditionalCalendarsModal,
  additionalCalendars,
  meetingToDuplicate,
  meetingToDelete,
}: ScheduleProps): ReactElement => {

  return (
    <CabinetPage
      pageName={'Schedule'}
      headerBackgroundColor={colors.white900}
      headerContent={<>
        <CabinetModal
          open={currentMeetingErrors.length > 0 ? errorModalOpen : false}
          component={
            <MeetingErrors
              meeting={currentMeeting}
              meetingErrors={currentMeetingErrors}
              handleResolution={handleResolution}
              calendars={calendars}
            />
          }
          onClose={() => setErrorModalOpen(false)}         
        />
        <ScheduleHeaderContainer
          selectedLeaders={selectedLeaders}
          onLeaderSelect={handleLeaderSelect}
          creatingMeeting={!!newMeeting}
          onCancel={handleCancel}
          currentMeeting={currentMeeting}
          opencalendarsModal={() => navigate(PAGE_URL.MANAGE_CALENDARS)}
          onCreateMeeting={handleCreateOneOffMeeting}
          onCreatePoll={handleCreatePoll}
          onCreateReusableMeeting={handleCreateReusableMeeting}
        />
      </>}
    >
      {((!eventsExists && loadingEvents) || !allLoaded) && (
        <Box sx={{
          position: 'absolute',
          zIndex: 200,
          height: '100%',
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
          <CabSpinner scale={4} color='inherit'/>
        </Box>
      ) }
      {allLoaded && (
        <Box display="flex" flex={1} height="100%">
          <Box
            display="flex"
            flex={1}
            flexDirection="column"
            // for open side panel offset
            marginRight={openMeetingSettings ? 0 : `-${CAB_PANEL_WIDTH}px`}
          >
            <CalendarSchedulerContainer
              selectedLeaders={selectedLeaders}
              leaderHasAssociations={leaderHasAssociations}
              userHasGrant={hasGrant}
              currentMeetingId={currentMeetingId}
              displayCalendars={displayCalendars}
              // This should be refactored at some point to use calendar accesses
              additionalCalendarIds={additionalCalendarPks}
              onUpdateAdditionalCalendar={handleUpdateAdditionalCalendars}
              loadingCalendars={loadingCalendars}
              onCalendarClick={handleCalendarClick}
              openAdditionalCalendarsModal={handleOpenAdditionalCalendarsModal}
              handleCalendarTimezoneSelected={handleCalendarTimezoneSelected}
              handleSecondaryTimezoneSelected={handleSecondaryTimezoneSelected}
              currentDateRangeInfo={currentDateRangeInfo}
              onDateChange={setCurrentDateRangeInfo}
              //Scheduler
              calendarTimezoneSelected={calendarTimezoneSelected}
              secondaryTimezonesSelected={secondaryTimezonesSelected}
              handleOpenMeeting={handleOpenMeeting}
              selectedSlots={selectedSlots}
              recurringSlots={recurringSlots}
              showAllRecurringSlots={showAllRecurringSlots}
              onToggleShowAllRecurringSlots={handleToggleShowAllRecurringSlots}
              handleSlotsCreated={handleCreateSlot}
              handleEditSlot={handleEditSlot}
              handleDeleteSlots={handleDeleteSlots}
              handleDuplicateMeeting={handleDuplicateMeeting}
              handleDeleteMeeting={handleDeleteMeeting}
              handleShareMeeting={handleShareMeeting}
              handleCopyLink={handleCopyLink}
              numHiddenCalendarTeammates={numHiddenCalendarTeammates}
              sx={calendarSchedulerSx}
            />
          </Box>
          {currentMeeting?.use_template_parent && currentMeeting.template_parent ? (
            <ReusableMeetingModal
              open={!!currentMeeting}
              meeting={currentMeeting}
              onClose={handleCancel}
              onConvertToOneOff={() => handleConvertToOneOff(currentMeeting.id)}
              onEditReusable={() => currentMeeting.template_parent && handleOpenMeeting(currentMeeting.template_parent)}
            />
          ) : (
            <MeetingSettingsContainer
              leaderMap={leaderMap}
              currentMeetingId={currentMeetingId}
              meetingSlots={currentMeetingSlots}
              onUpdateMeetingName={updateSlotNames}
              selectedLeaders={selectedLeaders}
              activeCalendars={activeCalendars}
              calendarTimezone={calendarTimezoneSelected}
              secondaryTimezones={secondaryTimezonesSelected}
              onDeleteSlots={handleDeleteSlots}
              onRemoveLeader={handleRemoveLeader}
              onAddLeader={handleAddLeader}
              onSetSelectedLeaders={setSelectedLeaders}
              onShowErrors={setErrorModalOpen}
              meetingErrors={currentMeetingErrors}
              onShare={handleShareCurrentMeeting}
              onCancel={handleCancel}
              openMeetingSettings={openMeetingSettings}
              slotsAreRecalculating={updatingSlots}
              pollUpdateOpen={pollUpdateOpen}
              onPollUpdateAccept={handlePollUpdateAccept}
              onPollUpdateReject={handlePollUpdateReject}
              invalidMeetingSlots={invalidatedParticipantVotes}
              onExcludedSlotsCreated={handleCreateExcludeSlots}
              onEditSlot={handleEditSlot}
              additionalCalendars={additionalCalendarPks}
              leaderCalendarOptions={leaderCalendarOptions}
              onCalendarTimezoneSelected={handleCalendarTimezoneSelected}
            />
          )}
          <ScheduleShareModalContainer
            key={shareMeetingModalMeetingId}
            meetingId={shareMeetingModalMeetingId}
            onModalClose={() => setShareMeetingModalMeetingId(-1)}
            modalOpen={shareMeetingModalMeetingId > 0}
            showEdit
          />
          <DuplicateMeetingModal
            open={!!meetingToDuplicate}
            onClose={() => setDuplicateMeetingModalMeetingId(-1)}
            meeting={meetingToDuplicate}
            submitDuplicate={handleSubmitDuplicateMeeting}
          />
          <DeleteMeetingModal
            open={!!meetingToDelete}
            onClose={() => setDeleteMeetingModalMeetingId(-1)}
            meeting={meetingToDelete}
            submitDelete={handleSubmitDeleteMeeting}
          />
          {openAdditionalCalendarsModal && (
            <AdditionalCalendars
              isOpen={openAdditionalCalendarsModal} 
              onDone={handleUpdateAdditionalCalendars}
              onCancel={() => setOpenAdditionalCalendarsModal(false)}
              selectedCalendars={additionalCalendars}
              selectedLeaders={selectedLeaders}
              onSelectLeaders={handleLeaderSelect}
            />
          )}
        </Box>
      )}
    </CabinetPage>
  );
};

export default memo(Schedule);
