import { isEqual, uniqBy } from 'lodash-es';
import { ReactElement, memo, useCallback, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { PAGE_URL } from '../../../constants';
import {
  RootState, actions, MeetingSlot, MeetingHoldEventError, Meeting, MeetingUpdate, MeetingCreate,
  RecurringTimes, InvalidParticipantVotes, ThunkDispatchType, MeetingLeader, Leader, Calendar,
  MeetingLeaderInfo, GlobalModalComponentName,
  PrivateExternalParticipant
} from '../../../store';
import { fetchSchedulingPreferences, updateSchedulingPrefs } from '../../../store/schedule/actions';
import { useMountEffect } from '../../../utils/hooks';
import { ExcludedSlotInfo, TimeZone } from '../../../utils/scheduleUtils';
import MeetingSettings from './MeetingSettings';
import { useCabinetText } from '../../../CabinetContext';
import { useDeepCompareEffect } from 'react-use';
import { generateUniqueUnsavedCabinetId } from '../../../utils/dataUtils';
import { router } from '../../../router';
import { CabModal } from '@CabComponents/CabModal';
import { CabButton } from '@CabComponents/CabButton';


export interface MeetingSettingsContainerProps {
  currentMeetingId: number | undefined;
  activeCalendars: number[];
  calendarTimezone: TimeZone | undefined;
  secondaryTimezones: (TimeZone | undefined)[];
  onDeleteSlots: (slots: MeetingSlot[]) => void;
  onRemoveLeader: (meetingId: number, leaderId: number) => void;
  onAddLeader: (meetingId: number, id: number) => void;
  onSetSelectedLeaders: (leaderIds: number[]) => void;
  onUpdateMeetingName: (name: string) => void;
  meetingSlots: MeetingSlot[];
  selectedLeaders: number[];
  onShowErrors: (show: boolean) => void;
  meetingErrors: MeetingHoldEventError[]
  onShare: () => void;
  onCancel: () => void;
  openMeetingSettings: boolean;
  slotsAreRecalculating: boolean;
  pollUpdateOpen: boolean;
  onPollUpdateAccept: () => void;
  onPollUpdateReject: () => void;
  invalidMeetingSlots: InvalidParticipantVotes;
  onExcludedSlotsCreated: (slots: ExcludedSlotInfo[]) => void;
  onEditSlot: (eventId: string, start: Date, end: Date, isExcluded: boolean) => void;
  additionalCalendars: number[];
  leaderMap: {[key: string]: Leader}
  leaderCalendarOptions: Calendar[];
  onCalendarTimezoneSelected?: (timezone: TimeZone) => void;
}


const MeetingSettingsContainer = ({
  currentMeetingId, activeCalendars, meetingSlots, meetingErrors, calendarTimezone, secondaryTimezones,
  selectedLeaders, openMeetingSettings, slotsAreRecalculating, pollUpdateOpen, invalidMeetingSlots,
  additionalCalendars, leaderMap, leaderCalendarOptions, onSetSelectedLeaders, onCalendarTimezoneSelected,
  onShowErrors, onDeleteSlots, onShare, onUpdateMeetingName, onCancel, onRemoveLeader, onAddLeader,
  onPollUpdateAccept, onPollUpdateReject, onEditSlot, onExcludedSlotsCreated,
}: MeetingSettingsContainerProps): ReactElement => {

  const user = useSelector((state: RootState) => state.auth.user);
  const leaders = useSelector((state: RootState) => state.leaders);
  const calendars = useSelector((state: RootState) => state.schedule.calendars);
  const meeting = useSelector((state: RootState) => currentMeetingId
    ? state.schedule.meetings[currentMeetingId]
    : state.schedule.newMeeting
  );
  const schedulingPrefs = useSelector((state: RootState) => state.schedule.schedulingPrefs);
  const zoomSettings = useSelector((state: RootState) => state.schedule.zoomSettings);
  const meetingRooms = useSelector((state: RootState) => state.schedule.meetingRooms);
  const presetLocations = useSelector((state: RootState) => state.schedule.presetLocations);
  const participantAutoCompleteOptions = useSelector((state: RootState) => (
    state.schedule.participantAutoCompletOptions
  ));

  const navigate = router.navigate;
  const dispatch = useDispatch<ThunkDispatchType>();

  const [dragTimeslotsText, holdTimesText] = useCabinetText(['meeting-drag-timeslots', 'hold-times']);
  const [deleteAttendeeId, setDeleteAttendeeId] = useState<number | null>(null);
  const [removeLeaderId, setRemoveLeaderId] = useState<number | null>(null);

  useMountEffect(() => {
    dispatch(fetchSchedulingPreferences());
  });

  const handleMeetingUpdate = useCallback(async (
    meetingUpdate: MeetingUpdate, files?: File[]
  ) => {
    if (meetingUpdate.id < 0) {
      // remove undefined values
      Object.keys(meetingUpdate).forEach((k) => {
        if (meetingUpdate[k as keyof MeetingUpdate] === undefined) {
          delete meetingUpdate[k as keyof MeetingUpdate];
        }
      });
      await dispatch(actions.schedule.updateNewMeeting(meetingUpdate));
    } else {
      await dispatch(actions.schedule.updateMeeting(meetingUpdate, [], files));
    }
    if (meetingUpdate.title) {
      onUpdateMeetingName(meetingUpdate.title);
    }
  }, [dispatch, onUpdateMeetingName]);

  const handleMeetingCreate = useCallback(async (
    mtgObj: MeetingCreate,
    files?:File[], 
    participants?: PrivateExternalParticipant[],
    meetingCreateLeaders?: MeetingLeader[],
  ) => {
    try {
      const res = await (dispatch(actions.schedule.createMeeting(
        mtgObj,
        meetingSlots.filter(slot => slot.id < 0),
        Object.values(mtgObj.questions).filter(question => question.id < 0),
        files, (participants || []), meetingCreateLeaders
      )) as unknown as Promise<{ meeting: Meeting }>);
 
      navigate(`${PAGE_URL.SCHEDULE}/${res.meeting.id}`, {replace: true});
      // setSaving(false);
    } catch (err) {
      alert(`Error: Meeting could not be created. Please try again
        later or email support@joincabinet.com for assistance.`);
      // setSaving(false);
    }
  }, [dispatch, meetingSlots, navigate]);

  const handleSaveRecurringTimes = useCallback((recurringTimes?: RecurringTimes) => {
    if (currentMeetingId) {
      dispatch(actions.schedule.updateMeeting({ id: currentMeetingId, recurring_times: recurringTimes || null}, []));
    } else {
      dispatch(actions.schedule.updateNewMeeting({ recurring_times: recurringTimes || undefined}));
    }
  }, [currentMeetingId, dispatch]);

  const updateExecHoldNoticePref = (value: boolean) => {
    if (schedulingPrefs.user_prefs) {
      dispatch(updateSchedulingPrefs({...schedulingPrefs.user_prefs, silence_executive_hold_notice: value}));
    }
  };

  const deleteMeetingFile = useCallback((id: number, meetingId: number) => {
    dispatch(actions.schedule.deleteMeetingFile(id, meetingId));
  }, [dispatch]);

  const closeDeleteModal = () => {
    setDeleteAttendeeId(null);
    setRemoveLeaderId(null);
  };

  const handleDeleteAttendee = async (attendeeId: number) => {
    if (!meeting) return;
    closeDeleteModal();
    await dispatch(actions.schedule.deleteMeetingParticipant(attendeeId, meeting.id, meeting.id > 0));
  };

  const handleOpenUpgradeModal = () => dispatch(actions.globalModal.openModal(GlobalModalComponentName.CABINET_PROMO));

  const handleRemoveMeetingLeader = (meetingLeaderId: MeetingLeader['id']) => {
    if (!meeting) return;
    const leaderId = meeting.leader_info?.find(l => l.meeting_leader_id === meetingLeaderId)?.id;
    if (leaderId != null) {
      closeDeleteModal();
      onRemoveLeader(currentMeetingId || -1, leaderId);
    }
  };

  const handleAddZoom = () => {
    navigate(PAGE_URL.INTEGRATION_SETTINGS);
  };

  let holdCalendarOptions = activeCalendars.map(calId => {
    const assoc = calendars.find(association => association.id === calId);
    const availCal = calendars.find(cal => cal.id === assoc?.id);
    return {
      id: calId,
      calendar_id: assoc?.calendar_id || '',
      name: availCal?.summary || '',
      can_edit: availCal?.canEdit
    };
  }).filter(cal => cal.can_edit);

  // add current hold calendars to options and de-dupe
  if (meeting?.hold_calendar_info) {
    holdCalendarOptions = uniqBy([
      ...holdCalendarOptions,
      ...(meeting.hold_calendar_info || []).map(cal => ({
        id: cal.id, calendar_id: cal.calendar_id, name: cal.calendar_name || 'Unknown Calendar', can_edit: false,
      })),
    ], cal => cal.id);
  }

  const isOwner = user?.id === meeting?.create_user?.id;

  // sync selected leaders with meeting leaders
  useDeepCompareEffect(() => {
    if (!isOwner || !meeting || !openMeetingSettings) return;

    const leadersAreNotEqual = !isEqual(new Set(meeting.leaders), new Set(selectedLeaders));
    if (leadersAreNotEqual && selectedLeaders.length > 0) {
      const newLeaderInfo = selectedLeaders.map(sl => {
        let leaderInfo = meeting.leader_info?.find(li => li.id === sl);
        if (!leaderInfo) {
          const ldr = leaders.leaders.find(l => l.id === sl);
          if (!ldr) return null;
          leaderInfo = {
            ...ldr,
            meeting_leader_id: generateUniqueUnsavedCabinetId(),
            required: true,
            prevent_conflicts: true,
            view_calendar: true,
            should_invite: true,
          };
        }
        return leaderInfo;
      }).filter((l): l is MeetingLeaderInfo => !!l);
      handleMeetingUpdate({
        id: meeting.id,
        leaders: selectedLeaders,
        leader_info: newLeaderInfo,
      });
    }
  }, [selectedLeaders]);

  // sync meeting leaders with selected leaders
  useDeepCompareEffect(() => {
    if (!isOwner || !meeting || !openMeetingSettings) return;

    const leadersAreNotEqual = !isEqual(new Set(meeting.leaders), new Set(selectedLeaders));

    if (leadersAreNotEqual && meeting.leaders && meeting.leaders.length > 0) {
      onSetSelectedLeaders(meeting.leaders);
    }
  }, [meeting?.leaders || []]);

  return (
    <>
      <MeetingSettings
        meetingSlots={meetingSlots}
        onDeleteSlots={onDeleteSlots}
        onRemoveLeader={setRemoveLeaderId}
        handleDeleteAttendee={setDeleteAttendeeId}
        calendarTimezone={calendarTimezone}
        secondaryTimezones={secondaryTimezones}
        holdCalendarOptions={holdCalendarOptions}
        leaderMap={leaderMap}
        meeting={meeting}
        showHolds={!!user?.features.EXEC_HOLDS}
        onShowErrors={onShowErrors}
        meetingErrors={meetingErrors}
        onSubmit={handleMeetingUpdate}
        onCreateNewMeeting={handleMeetingCreate}
        onShare={onShare}
        onCancel={onCancel}
        openUpgradeModal={handleOpenUpgradeModal}
        open={openMeetingSettings}
        schedulingPrefs={schedulingPrefs}
        isOwner={isOwner}
        updateExecHoldNoticePref={updateExecHoldNoticePref}
        slotsAreRecalculating={slotsAreRecalculating}
        pollUpdateOpen={pollUpdateOpen}
        pollUpdateHandlerAccept={onPollUpdateAccept}
        pollUpdateHandlerReject={onPollUpdateReject}
        invalidMeetingSlots={invalidMeetingSlots}
        dragTimeslotsText={dragTimeslotsText}
        holdTimesText={holdTimesText}
        handleSaveRecurringTimes={handleSaveRecurringTimes}
        onExcludedSlotsCreated={onExcludedSlotsCreated}
        onEditSlot={onEditSlot}
        deleteMeetingFile={deleteMeetingFile}
        features={user?.features}
        oauth_grant_details={user?.oauth_grant_details}
        additionalCalendars={additionalCalendars}
        user={user}
        zoomSettings={zoomSettings}
        onAddZoom={handleAddZoom}
        leaders={leaders}
        leaderCalendarOptions={leaderCalendarOptions}
        calendars={calendars}
        meetingRooms={meetingRooms}
        presetLocations={presetLocations}
        participantAutocompleteOptions={participantAutoCompleteOptions}
        onCalendarTimezoneSelected={onCalendarTimezoneSelected}
      />
      {meeting && (!!deleteAttendeeId || !!removeLeaderId) && (
        <CabModal
          open={!!deleteAttendeeId || !!removeLeaderId}
          title={'Remove Attendee'}
          onClose={closeDeleteModal}
          isAlert={true}
          noFullScreen={true}
          text={meeting.is_poll 
            ? 'All votes for times that this person submitted will be removed.' 
            : 'Are you sure you want to remove this person from the meeting?'}
          actionButtons={
            <>
              <CabButton buttonType='secondary' onClick={closeDeleteModal}>
                Cancel
              </CabButton>
              <CabButton onClick={ deleteAttendeeId ? () => handleDeleteAttendee(deleteAttendeeId)
                : removeLeaderId ? () => handleRemoveMeetingLeader(removeLeaderId) : undefined} 
              color="error"
              >
                Delete
              </CabButton>
            </>
          }
        />
      )}
    </>
  );
};

export default memo(MeetingSettingsContainer);
